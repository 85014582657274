var Texts = (function () {

    var templateTextItem;

    function Init() {
        templateTextItem = $('template#tplTextItem').remove().contents();

        localState.texts = localState.texts || {};
        localState.texts.itemIndex = localState.texts.itemIndex || 0;

        renderList();
        renderDetails();
    }

    function renderList(group) {
        var texts = AppData.texts.filter(function(e) {
            return e.active
        }),
        fragmentTexts = $(document.createDocumentFragment()),
        self = $("#texts #textsList");

        if (group != undefined) {
            texts = AppData.texts.filter(function(e) {
                return e.text_group_id == group;
            })
        }

        $.each(texts, function(i,e) {
            var clone = templateTextItem.clone();
            clone.attr('data-index', e.id);
            clone.find(".data-title").html(e.title[localState.lang]);
            clone.appendTo(fragmentTexts);

        });
        self.html(fragmentTexts);
        $('#texts').find('.noresults').toggle(Boolean(!texts.length));
    }

    function update() {
        $.post('api/', {
            do: 'getTexts'
        }, function (data) {
            AppData.texts = data;
            renderList();
            renderDetails();
            data = null;
        });
    }

    function showDetails(id) {
        writeDbLog("open text", String(id), "user_logs", "texts");
        if(id) {

            if (typeof id === "string" && id.indexOf("group:") > -1) {
                var group = AppData.textgroups.find(function (el) {
                    return el.id == id.split(":")[1];
                });

                if (group != undefined) {
                    renderList(group.id);
                    Layout.show("texts");
                    return;
                }
            }
            if (localState.texts != undefined) {
                if (id != localState.texts.itemIndex || localState.texts.itemIndex === 0) {
                    localState.texts.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.texts = {'itemIndex': String(id)};
                renderDetails();
            }

            Layout.show('texts-details');
        }
    }

    function showList(target) {
        if (target == "") {
            renderList();
        }
    }

    function renderDetails() {
        var item = $.grep(AppData.texts, function (e) {
            return e.id == localState.texts.itemIndex;
        });

        if (item.length > 0) {
            if (item[0].text[localState.lang] != undefined) {
                if (item[0].text[localState.lang].indexOf("{{name}}") > -1) {
                    item[0].text[localState.lang] = item[0].text[localState.lang].replace("{{name}}", AppData.profile.name[localState.lang]);
                }
                if (item[0].text[localState.lang].indexOf("{{email}}") > -1) {
                    item[0].text[localState.lang] = item[0].text[localState.lang].replace("{{email}}", AppData.profile.email);
                }
                var textsdetails = $('#texts-details');

                let text = item[0].text[localState.lang];

                let myRegexp = /#([a-z0-9áéúőóüöíű ;.,?!/_-]*)#/gi;
                let match = myRegexp.exec(text);
                while (match != null) {
                    let splitted = match[1].split(";");
                    if (splitted.length == 4) {
                        if (splitted[0] == "1") {
                            text = text.replace(match[0], '<button class="btn bcbtn" data-broadcast="' + splitted[2].trim() + '" data-channel="'+splitted[3]+'">' + splitted[1].trim() + '</button>');
                        } else {
                            text = text.replace(match[0], '<button class="btn vstagebtn" data-room="' + splitted[2].trim() + '" data-channel="'+splitted[3]+'">' + splitted[1].trim() + '</button>');
                        }
                    }
                    match = myRegexp.exec(text);
                }

                $('#texts-details .header-secondary .data-label').html(nl2brStrict(item[0].title[localState.lang]));

                textsdetails.find('.data-text-title').html(nl2brStrict(item[0].title[localState.lang]));
                textsdetails.find('.data-text').html(text);
            }
        }
    }

    function clickText(e) {
        showDetails(this.dataset.index);
    }

    $(document).on('click', '#texts .item', clickText);

    $(document).on('click', "#texts-details .vstagebtn", function (){
        let tc = $(this).attr("data-room");
        let ch = $(this).attr("data-channel");

        if (ch != undefined && ch != "undefined" && ch != "") {
            Channels.setChannel(ch);
        }

        openTrueConfCall(tc, 'workshop');
        if ($("#landing .navplusbutton i").hasClass("fa-minus")) {
            $("#landing .navplusbutton .open-main").trigger("mouseup");
        }
    });

    $(document).on('click', "#texts-details .bcbtn", function (){
        let bc = $(this).attr("data-broadcast");
        let ch = $(this).attr("data-channel");

        if (ch != undefined && ch != "undefined" && ch != "") {
            Channels.setChannel(ch);
        }

        Layout.hideAll();
        Broadcast.playStreamByURL("https://"+bc);
        if ($("#landing .navplusbutton i").hasClass("fa-minus")) {
            $("#landing .navplusbutton .open-main").trigger("mouseup");
        }
    });

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        renderList: renderList,
        update: update,
        showDetails: showDetails,
        showList: showList,
        renderDetails: renderDetails
    }
})();
